import axios from "axios";
import { navigate } from "../utils/rootNavigator";
import { getToken } from "./getToken";

const {
  REACT_APP_BASEURL,
  REACT_APP_USER,
  REACT_APP_COPY_FOLDER_URL,
  REACT_APP_COPY_FOLDER_TOKEN,
}: string | any = process.env;

export const axiosInstanceCopyFolder = axios.create({
  baseURL: REACT_APP_COPY_FOLDER_URL,
});

axiosInstanceCopyFolder.interceptors.request.use(
  (config: any) => {
    const { token, role } = getToken();

    if (!token) Promise.reject("Authorized.");

    config.headers.Authorization = REACT_APP_COPY_FOLDER_TOKEN;

    if (role?.includes("backstage_dev")) {
      config.headers["Accept-Profile"] = "api_dev";
      config.headers["Content-Profile"] = "api_dev";
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);



const axiosInstance = axios.create({
  baseURL: REACT_APP_BASEURL,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    const { token, role } = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      if (role?.includes("backstage_dev")) {
        if (config.url === "/order/tracker") {
          config.headers["Accept"] = "application/vnd.pgrst.object+json";
          config.headers["Prefer"] = "params=single-object";
          config.headers["Content-Profile"] = "api_dev";
        } else if (config.url.includes("tracker")) {
          config.headers["Accept-Profile"] = "api_dev";
        } else {
          config.headers["Accept"] = "application/vnd.pgrst.object+json";
          config.headers["Prefer"] = "params=single-object";
          config.headers["Accept-Profile"] = "api_dev";
          config.headers["Content-Profile"] = "api_dev";
        }
      } else {
        config.headers["Accept"] = "application/vnd.pgrst.object+json";
        config.headers["Prefer"] = "params=single-object";
      }

      if (config.url.includes("/logo")) {
        config.headers["Prefer"] = "return=representation";
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  async (error) => {
    const errConfig = error?.config;

    if (!error.response) {
      return Promise.reject(error);
    }

    if (errConfig?.url !== "/login" && error.response) {
      if (
        error.response.status === 401 ||
        (error.response.status === 403 && !errConfig._retry)
      ) {
        const { refreshToken } = getToken();
        if (refreshToken) {
          errConfig._retry = true;
          try {
            const res = await axiosInstance.post("/value", {
              refreshToken: refreshToken,
            });

            localStorage.setItem(REACT_APP_USER, JSON.stringify(res?.data));
            return axiosInstance(errConfig);
          } catch (err) {
            navigate("/logout");
            return Promise.reject(err);
          }
        } else {
          navigate("/logout");
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
