import React from "react";

export default function ReportIcon({ color }: { color: string }) {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 6.625H7.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.125 10.375H7.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 2.25H9.75C10.0815 2.25 10.3995 2.3817 10.6339 2.61612C10.8683 2.85054 11 3.16848 11 3.5V12.25C11 12.5815 10.8683 12.8995 10.6339 13.1339C10.3995 13.3683 10.0815 13.5 9.75 13.5H2.25C1.91848 13.5 1.60054 13.3683 1.36612 13.1339C1.1317 12.8995 1 12.5815 1 12.25V3.5C1 3.16848 1.1317 2.85054 1.36612 2.61612C1.60054 2.3817 1.91848 2.25 2.25 2.25H3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 1H4.125C3.77982 1 3.5 1.27982 3.5 1.625V2.875C3.5 3.22018 3.77982 3.5 4.125 3.5H7.875C8.22018 3.5 8.5 3.22018 8.5 2.875V1.625C8.5 1.27982 8.22018 1 7.875 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
