import ConsultantIcon from "../components/svgs/ConsultantIcon";
import DashboardIcon from "../components/svgs/DashboardIcon";
import OrderReviewIcon from "../components/svgs/OrderReviewsIcon";
import ReportIcon from "../components/svgs/ReportIcon";
import { IsideNavModel } from "../models/sideNav";

export const sideNavData: IsideNavModel[] = [
  { id: 1, icon: DashboardIcon, name: "Dashboard", path: "/dashboard" },
  {
    id: 2,
    icon: OrderReviewIcon,
    name: "Deals",
    path: "/deals",
  },
  { id: 3, icon: ConsultantIcon, name: "Consultants", path: "/consultants" },
  {
    id: 4,
    icon: ReportIcon,
    name: "Operations Reports",
    path: "/operations-reports",
  },
];
