import React from "react";

const UserSvg = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.45">
        <path
          d="M11 11.0961V9.77797C11 9.07876 10.7222 8.40819 10.2278 7.91378C9.7334 7.41936 9.06283 7.1416 8.36362 7.1416H3.09089C2.39169 7.1416 1.72112 7.41936 1.2267 7.91378C0.732288 8.40819 0.454529 9.07876 0.454529 9.77797V11.0961"
          fill="#181818"
        />
        <path
          d="M5.72743 5.72201C7.18345 5.72201 8.36379 4.54167 8.36379 3.08564C8.36379 1.62962 7.18345 0.44928 5.72743 0.44928C4.2714 0.44928 3.09106 1.62962 3.09106 3.08564C3.09106 4.54167 4.2714 5.72201 5.72743 5.72201Z"
          fill="#181818"
        />
      </g>
    </svg>
  );
};

export default UserSvg;
