import React from "react";
import { motion } from "framer-motion";
import { useAppDispatch } from "../../../store/storeConfig";
import { backdrop, modalFile } from "../../../utils/anims";
import { useAppSelector } from "./../../../store/storeConfig";
import styles from "./index.module.css";
import { showFile } from "../../../store/slices/showFileSlice";
import { downloader } from "../../../utils/downloader";
import { CancelIcon, rotate } from "../../../exports";

export default function ShowFile() {
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.showFileSlice);
  const { data, filename } = useAppSelector((state) => state.fileHandlerSlice);

  const [deg, setDeg] = React.useState(0);

  const rotateFunc = () => setDeg((deg) => deg + 90);

  return (
    <>
      {show && (
        <motion.section
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={`${styles.backdrop}`}
        >
          <div className=" d-flex justify-content-end ">
            {/\.(jpe?g|png)$/i.test(filename) && (
              <button
                style={{ zIndex: 2 }}
                onClick={rotateFunc}
                className="btn btn-success"
              >
                <img
                  src={rotate}
                  alt="download icon"
                  className="img-fluid "
                  width="18px"
                  height="18px"
                />
              </button>
            )}
            {downloader(data, filename)}
            <button
              onClick={() => {
                dispatch(showFile(false));
              }}
              className="d-flex align-items-center border border-danger bg-light rounded px-3"
            >
              <img
                src={CancelIcon}
                alt="cancel icon"
                className="img-fluid"
                width="15px"
                height="15px"
                style={{ filter: "revert" }}
              />
            </button>
          </div>
          {/\.(jpe?g|png)$/i.test(filename) ? (
            <>
              <div
                className="h-100 w-100 pb-5"
                style={{ transform: `rotate(${deg}deg)`, zIndex: 1 }}
              >
                <motion.img
                  variants={modalFile}
                  whileTap={{ scale: 1.2 }}
                  src={`${data}`}
                  className={`${styles.image}`}
                  alt={`${data}`}
                />
              </div>
            </>
          ) : (
            <div className="w-100 text-center mt-3" style={{ height: "85vh" }}>
              <motion.iframe
                variants={modalFile}
                src={`${data}#view=fitH`}
                title={filename}
                name={filename}
                height="100%"
                width="90%"
              />
            </div>
          )}
        </motion.section>
      )}
    </>
  );
}
