import React from "react";

import { useAppSelector } from "./../../../store/storeConfig";
import { Redirect, Route } from "react-router-dom";
import { readLocalStorage } from "./../../../utils/readLocalStorage";

interface IPrivateProps {
  path: string;
  component: React.FC;
  exact?: boolean;
}

function PrivateRoute({ path, component: Component, exact }: IPrivateProps) {
  const { authenticated } = useAppSelector((state) => state.authSlice);
  const { REACT_APP_USER }: any = process.env;

  return (
    <>
      {authenticated || readLocalStorage(REACT_APP_USER) ? (
        <Route path={path} exact={exact}>
          <Component />
        </Route>
      ) : (
        <Redirect to="/login" from={path} />
      )}
    </>
  );
}

export default React.memo(PrivateRoute);
