import authSlice from "./slices/authSlice";
import tableSlice from "./slices/tableSlice";
import modalSlice from "./slices/modalSlice";
import fileHandlerSlice from "./slices/fileHandlerSlice";
import showFileSlice from "./slices/showFileSlice";

export const reducer = {
  authSlice,
  tableSlice,
  modalSlice,
  fileHandlerSlice,
  showFileSlice,
};
