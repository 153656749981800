import React from "react";
import { useAppSelector } from "./../../../store/storeConfig";
import { Redirect, Route } from "react-router-dom";
import { readLocalStorage } from "../../../utils/readLocalStorage";

interface IPublicRouteProps {
  path: string;
  component: React.FC;
  exact: boolean;
}

function PublicRoute({ path, component, exact }: IPublicRouteProps) {
  const { authenticated } = useAppSelector((state) => state.authSlice);
  const { REACT_APP_USER }: any = process.env;
  return (
    <>
      {authenticated || readLocalStorage(REACT_APP_USER) ? (
        <Redirect to="/deals" />
      ) : (
        <Route path={path} exact={exact} component={component} />
      )}
    </>
  );
}

export default React.memo(PublicRoute);
