import { handleAuthentication } from "../store/slices/authSlice";
import axiosInstance from "../axios-config";

import { riskAppTerms } from "../models/riskAppLogo";

import { toast } from "react-toastify";

// import { getToken } from "./../axios-config/getToken";

const { REACT_APP_BASEURL }: string | any = process.env;

// const { token } = getToken();

const toastError = (err: string, time: number) => {
  toast.error(err, {
    position: "bottom-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

const toastInfo = (msg: string, time: number) => {
  toast.info(msg, {
    position: "bottom-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

const toastSucess = (msg: string, time: number) => {
  toast.info(msg, {
    position: "bottom-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

type TPostLogo = {
  path: string;
  payload: any;
  setLogo: (arg: any) => void;
};

type TDeleteLogo = {
  payload: number;
  setLogo: (arg: any) => void;
};

type TUpdateLogo = {
  id: number;
  payload: any;
  setLogo: (arg: any) => void;
};

export const http = {
  login: async (path: string, data: any, history: any, dispatch: any) => {
    let { REACT_APP_USER }: any = process.env;
    try {
      let response: any = await axiosInstance.post(path, data);

      if (response?.status === 200) {
        localStorage.setItem(REACT_APP_USER, JSON.stringify(response?.data));
        dispatch(handleAuthentication(true));
        history.push("/deals");
      }
      return response;
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  getTable: async ({
    path,
    page,
    pageSize,
    searchKey,
    sortKey,
    filters,
  }: {
    path: string;
    page: number;
    pageSize: number;
    searchKey: string;
    sortKey: string;
    filters: string;
  }) => {
    const offset = page * pageSize;
    try {
      let url = `${path}?offset_var=${offset}&limit_var=${pageSize}${
        searchKey ? `&search_param=${searchKey}` : ""
      }`;

      // let url = `${path}?offset_var=${offset}&limit_var=${pageSize}${
      //   searchKey ? `&search_param=${searchKey}` : ""
      // }&status_param=${orderStatus}`;

      if (!!filters) url += `&${filters}`;

      if (!!sortKey)
        url += `&sort=${sortKey?.toLocaleLowerCase().split(" ").join("_")}`;

      let response: any = await axiosInstance.get(url);
      return response?.data;
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  getSingle: async (path: string, id: number) => {
    try {
      let response: any = await axiosInstance.get(`${path}/${id}`);
      if (response) {
        return response?.data;
      }
      return {};
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  post: async (path: string, data: any) => {
    try {
      let response: any = await axiosInstance.post(path, data);
      return response?.data;
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  put: async (path: string, data: any) => {
    try {
      let response: any = await axiosInstance.put(path, data);
      return response?.data;
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  postLogo: async ({ path, payload, setLogo }: TPostLogo) => {
    toastInfo("Saving icon, please wait.", 1000);
    try {
      let response: any = await axiosInstance.post(path, payload);
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem(riskAppTerms.riskAppLogo, payload.logo);
        toastInfo("Icon saved successfully", 2000);
        return response?.data;
      }
    } catch (e: any) {
      setLogo("");
      toastError("Sorry, unable to upload icon.", 2000);
    }
  },
  get: async (path: string) => {
    try {
      let response: any = await axiosInstance.get(`${path}`);
      return response?.data;
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  getLogo: async (path: string, setLogo: any) => {
    try {
      let response: any = await axiosInstance.get(
        `${REACT_APP_BASEURL}${path}`
      );

      if (response.status === 200) {
        if (response.data) {
          setLogo(response.data);
          localStorage.setItem(riskAppTerms.riskAppLogo, response.data);
        }
        return response?.data;
      }
    } catch (e: any) {
      throw new Error(
        e?.response ? e?.response?.data?.message : "Something went wrong."
      );
    }
  },
  deleteLogo: async ({ payload, setLogo }: TDeleteLogo) => {
    toastInfo("Deleting icon. Please wait.", 1500);
    try {
      let response: any = await axiosInstance.delete(`/logo/${payload}`);
      if (response.status === 200) {
        localStorage.removeItem(riskAppTerms.riskAppLogo);
        setLogo("");
        toastSucess("Icon removed succesfully", 2000);
      }
    } catch (e: any) {
      toastError("Couldn't complete delete. Try again.", 2000);
      return e?.response ? e?.response?.data?.message : e;
    }
  },
  updateLogo: async ({ id, setLogo, payload }: TUpdateLogo) => {
    toastInfo("Updating icon. Please wait...", 1500);
    try {
      let response: any = await axiosInstance.put(`/logo/${id}`, payload);
      if (response.status === 200) {
        localStorage.setItem(riskAppTerms.riskAppLogo, payload?.logo);
        toastSucess("Icon updated successfully!", 2000);
      }
    } catch (e: any) {
      const logo = localStorage.getItem(riskAppTerms.riskAppLogo);
      if (logo) {
        setLogo(logo);
      } else {
        setLogo("");
      }
      toastError("Couldn't complete updating process. Try again.", 2000);
      return e?.response ? e?.response?.data?.message : e;
    }
  },
};
