import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showFileData } from "./../initial-data/index";

const showFileSlice = createSlice({
  name: "showFileSlice",
  initialState: showFileData,
  reducers: {
    showFile: (state, { payload }: PayloadAction<boolean>) => {
      state.show = payload;
    },
  },
});

export const { showFile } = showFileSlice.actions;

export default showFileSlice.reducer;
