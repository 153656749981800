import React from "react";

const ReviewOrderSections = React.lazy(
  () => import("../pages/ReviewOrderSections")
);
const Login = React.lazy(() => import("../pages/Login"));

const ReviewOrders = React.lazy(() => import("../pages/ReviewOrder"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const RootLogout = React.lazy(() => import("../pages/RootLogout"));
const DealTracker = React.lazy(() => import("./../pages/DealTracker"));
const OperationsReports = React.lazy(() => import("./../pages/OperationsReports"));


export {
  ReviewOrderSections,
  Login,
  ReviewOrders,
  Dashboard,
  NotFound,
  RootLogout,
  DealTracker,
  OperationsReports
};
