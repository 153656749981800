import React from "react";
import styles from "./index.module.css";

interface ITitleProps {
  title: string;
}

export default function Title({ title = "Enter a title" }: ITitleProps) {
  return (
    <div className={styles.title} style={{ flex: 1 }}>
      {title}
    </div>
  );
}
