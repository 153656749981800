import {useParams} from "react-router-dom";
import Title from "../review-order/header/title";
import React from "react";

const OperationsReportsIframe = () => {
  const operationsReportLink = localStorage.getItem("report");

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });

  return (
    <section
      style={{height: "100vh", width: "100%", background: "rgb(248, 248, 248)"}}
    >
      <div
        className="px-4 py-3 mb-4 bg-white"
        style={{width: "100%", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.03)"}}
      >
        <Title title="Operations Report" />
      </div>
      {isLoading && (
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100"
          style={{height: "92vh"}}
        >
          <div className="d-flex justify-content-center align-items-center my-2">
            <div className="spinner-grow spinner-grow-sm text-success"></div>
          </div>
          <div>One moment, please wait...</div>
        </div>
      )}

      <div
        className="px-3 h-100 "
        style={{display: isLoading ? "none" : "block", paddingBottom: "1em"}}
      >
        <iframe
          title={"operations reports"}
          src={operationsReportLink}
          style={{border: 0, outline: 0}}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </section>
  );
};

export default OperationsReportsIframe;
