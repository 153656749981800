export const readLocalStorage = (arg: string) => {
  if (localStorage.getItem(arg) === "undefined") return false;
  return Boolean(localStorage.getItem(arg));
};

export const saveSession = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getSession = (key: string) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (err) {
  }
};
