import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tableSliceData } from "../initial-data/";

const tableSlice = createSlice({
  name: "tableSlice",
  initialState: tableSliceData,
  reducers: {
    handlePageChange: (state, { payload }: PayloadAction<number>) => {
      state.queryPageIndex = payload;
    },
    handlePageSizeChange: (state, { payload }: PayloadAction<number>) => {
      state.queryPageSize = payload;
    },
    handleTotalCountChange: (state, { payload }: PayloadAction<number>) => {
      state.totalCount = payload;
    },
  },
});

export const {
  handlePageChange,
  handlePageSizeChange,
  handleTotalCountChange,
} = tableSlice.actions;

export default tableSlice.reducer;
