import React from "react";

import "./App.scss";

import Home from "./pages";

import { QueryClientProvider, QueryClient } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";

import { BrowserRouter as Router } from "react-router-dom";

import ErrorBoundaryProvider from "./components/error-boundary";

import { navigationRef } from "./utils/rootNavigator";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundaryProvider>
        <Router ref={navigationRef}>
          <Home />;
        </Router>
      </ErrorBoundaryProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
