import React from "react";

export default function DashboardIcon({ color }: { color: string }) {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4995 2.39038C13.4995 2.16119 13.406 1.93695 13.2325 1.76823C13.0582 1.59881 12.8174 1.5 12.562 1.5H11.2116H11.2113C10.9562 1.5 10.7156 1.59875 10.5414 1.76814C10.3679 1.93684 10.2745 2.1611 10.2745 2.39038V10.4984H13.4995V2.39038ZM12.562 0H11.2113H3.78811H2.43744C1.79772 0 1.18009 0.246838 0.72146 0.69268C0.262107 1.13922 4.27564e-07 1.74946 3.98722e-07 2.39038L0 11.2484C-1.86556e-08 11.6626 0.335786 11.9984 0.75 11.9984H5.47399L5.47496 11.9984L5.47594 11.9984L9.52374 11.9984H9.52446H9.52517H14.2495C14.6637 11.9984 14.9995 11.6626 14.9995 11.2484V2.39038C14.9995 1.74946 14.7374 1.13922 14.278 0.69268C13.8194 0.246838 13.2018 0 12.562 0ZM3.78742 1.5L3.78811 1.5C4.04323 1.5 4.28383 1.59875 4.45802 1.76814C4.6315 1.93684 4.72496 2.1611 4.72496 2.39038L4.72496 10.4984H1.5L1.5 2.39038C1.5 2.16119 1.59346 1.93695 1.76702 1.76823C1.9413 1.59881 2.18208 1.5 2.43744 1.5H3.78742Z"
        fill={color}
      />
    </svg>
  );
}
