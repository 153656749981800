import { motion } from "framer-motion";
import {
  backdrop,
  XtranslateRight,
  XtranslateLeft,
  modal,
} from "../../../utils/anims";
import { useAppSelector, useAppDispatch } from "./../../../store/storeConfig";
import { updateModal } from "../../../store/slices/modalSlice";
import styles from "./index.module.scss";
import { handleLogout } from "../../../utils/handleLogout";
import { useHistory } from "react-router";
import ReactDOM from "react-dom";

export default function Modal() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { show } = useAppSelector((state) => state.modalSlice);

  return (
    <>
      {show &&
        ReactDOM.createPortal(
          <motion.section
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`${styles.backdrop} text-center`}
          >
            <motion.section variants={modal} className={`${styles.modalBody}`}>
              <section>
                <div>
                  <span className={`${styles.logoutText} font-weight-bold`}>
                    Logout ?
                  </span>
                </div>

                <div>
                  <span className={`${styles.logoutText}`}>
                    Are you sure you want to Exit?
                  </span>
                </div>
              </section>

              <div className="d-flex flex-column align-items-center mt-5">
                <motion.button
                  variants={XtranslateLeft}
                  onClick={() => handleLogout(history, dispatch)}
                  className={`${styles.buttonConfirm} ${styles.buttonRadius} ${styles.buttonPadding} text-center w-75 `}
                >
                  Confirm
                </motion.button>

                <motion.button
                  variants={XtranslateRight}
                  onClick={() => dispatch(updateModal(false))}
                  className={`${styles.buttonCancel} ${styles.buttonRadius} ${styles.buttonPadding} w-75 font-weight-bold bg-white mt-3 `}
                >
                  Cancel
                </motion.button>
              </div>
            </motion.section>
          </motion.section>,
          document.body
        )}
    </>
  );
}
