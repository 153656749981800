import { download } from "../exports";

export function downloader(data: any, filename: string) {
  const handleClieDtsDownload = async () => {
    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", `${filename}`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <button
      type="button"
      className="btn btn-success mx-2"
      onClick={handleClieDtsDownload}
    >
      <img
        src={download}
        alt="download icon"
        className="img-fluid"
        width="20px"
        height="20px"
      />
    </button>
  );
}
