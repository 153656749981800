import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fileHandlerData, IFileHandlerProps } from "../initial-data/";

const fileHandlerSlice = createSlice({
  name: "fileHandlerSlice",
  initialState: fileHandlerData,
  reducers: {
    setFile: (
      state,
      { payload: { data, filename } }: PayloadAction<IFileHandlerProps>
    ) => {
      state.data = data;
      state.filename = filename;
    },
  },
});

export const { setFile } = fileHandlerSlice.actions;

export default fileHandlerSlice.reducer;
