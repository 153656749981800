const { REACT_APP_USER }: any = process.env;

export const getToken = (): any => {
  const data: any = localStorage.getItem(REACT_APP_USER);
  const token = JSON.parse(data)?.jwt?.token;
  const role = JSON.parse(data)?.role;
  let refreshToken = JSON.parse(data)?.jwt?.refreshToken;

  return { token, role, refreshToken };
};
