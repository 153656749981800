import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authSliceData } from "../initial-data/";

const authSlice = createSlice({
  name: "authSlice",
  initialState: authSliceData,
  reducers: {
    handleAuthentication: (state, { payload }: PayloadAction<boolean>) => {
      state.authenticated = payload;
    },
  },
});

export const { handleAuthentication } = authSlice.actions;

export default authSlice.reducer;
