import React from "react";

import {useLocation, useRouteMatch} from "react-router";
import {NavLink} from "react-router-dom";
import {sideNavData} from "../../data/side-nav";
import {DeleteLogoIcon, LogOutIcon, UploadLogoIcon} from "../../exports";
import styles from "./index.module.scss";
import {useAppSelector, useAppDispatch} from "./../../store/storeConfig";
import {updateModal} from "../../store/slices/modalSlice";
import {readLocalStorage} from "./../../utils/readLocalStorage";
import {getUserName} from "./../../utils/getUserName";

import {useGetLogo} from "../../apis";

import {http} from "../../apis/http";

import {riskAppTerms} from "../../models/riskAppLogo";

import UserSvg from "./../svgs/UserSvg";

import {getAuthorize} from "../../hooks/useBoxConnect";

const sideMenuList = ({
  navData: {id, name, icon: Icon, path},
  pathname,
  routeMatch,
  routeMatchTracker,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (path === "/dashboard" || path === "/consultants") {
      e.preventDefault();
    }
  };

  return (
    <section
      key={id}
      className={`${
        pathname === path ||
        routeMatch?.url?.includes(path) ||
        routeMatch?.url.includes("tracker")
          ? styles.activeBg
          : ""
      } px-3 py-3 my-3`}
      style={{cursor: "pointer"}}
    >
      <NavLink to={path} onClick={handleClick} className="text-decoration-none">
        <div className="d-flex align-items-center">
          <aside className="mr-2 d-flex align-self-center">
            <Icon
              color={`${
                pathname === path || routeMatch?.url?.includes(path)
                  ? "var(--risk-primary)"
                  : "#181818"
              }`}
            />
          </aside>
          <aside
            className={`position-relative ${
              pathname === path ||
              routeMatch?.url?.includes(path) ||
              routeMatchTracker?.url?.includes(path) > 0
                ? styles.nameGreen
                : styles.name
            } mx-1`}
          >
            <div>
              <span>{name}</span>
            </div>
            {(path === "/dashboard" || path === "/consultants") && (
              <aside
                className={`position-absolute small text-info px-1`}
                style={{borderRadius: "10px", fontSize: "0.7em"}}
              >
                <span>coming soon</span>
              </aside>
            )}
          </aside>
        </div>
      </NavLink>
    </section>
  );
};

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

type TAddIcon = {
  handleAddLogo: (e: any) => void;
  handleDeleteLogo: (e: any) => void;
  handleUpdateLogo: (e: any) => void;
  logo: any;
  setLogo: (arg: any) => void;
};

const RenderAddIcon = ({
  handleAddLogo,
  handleDeleteLogo,
  handleUpdateLogo,
  logo,
  setLogo,
}: TAddIcon) => {
  // const { data, isLoading, isError } = useGetLogo("/logo/1");
  useGetLogo("/logo/1", setLogo);

  const localLogo = localStorage.getItem(riskAppTerms.riskAppLogo);

  return (
    <>
      {logo || localLogo ? (
        <section
          className={`${logo || localLogo ? "" : styles.addLogo} ${
            styles.wrapper
          } d-flex justify-content-center flex-column align-items-center p-2 position-relative`}
        >
          <>
            <label className={`text-center`} style={{cursor: "pointer"}}>
              <input className="d-none" type="file" onChange={handleAddLogo} />
              <img
                src={logo || localLogo}
                className="img-fluid"
                width={"50%"}
                height={"70px"}
                alt="risk app logo"
              />
            </label>

            <div
              style={{cursor: "pointer"}}
              className={`${styles.showButtons} px-2 align-items-center justify-content-center position-absolute w-50 h-100`}
            >
              <label>
                <input
                  className="d-none"
                  type="file"
                  onChange={handleUpdateLogo}
                />

                <div className="btn btn-success btn-sm mt-2 p-0">
                  <img
                    src={UploadLogoIcon}
                    className="img-fluid"
                    width={"40%"}
                    height={"50px"}
                    alt="upload logo icon"
                  />
                </div>
              </label>
              <div
                onClick={handleDeleteLogo}
                className="btn btn-danger btn-sm ml-3 p-0"
              >
                <img
                  src={DeleteLogoIcon}
                  className="img-fluid"
                  width={"50%"}
                  height={"50px"}
                  alt="upload logo icon"
                />
              </div>
            </div>
          </>
        </section>
      ) : (
        <section
          className={`${styles.addLogo} d-flex justify-content-center align-items-center p-2`}
        >
          <label className={`text-center p-4`}>
            Add Logo&nbsp;
            <span className="font-weight-bold">+</span>
            <input className="d-none" type="file" onChange={handleAddLogo} />
          </label>
        </section>
      )}
    </>
  );
};

function SideMenu({isBoxConnect}: {isBoxConnect: boolean}) {
  const [logo, setLogo] = React.useState<any>(null);

  const handleAddLogo = React.useCallback(async (e: any) => {
    e.persist();
    if (e.target?.files?.[0]) {
      const base64data: any = await getBase64(e.target.files[0]);
      setLogo(base64data);
      http.postLogo({
        ...{path: "/logo", payload: {id: 1, logo: base64data}, setLogo},
      });
    }
  }, []);

  const handleDeleteLogo = React.useCallback(
    async () => http.deleteLogo({payload: 1, setLogo}),
    []
  );

  const handleUpdateLogo = React.useCallback(async (e: any) => {
    e.persist();
    if (e.target?.files?.[0]) {
      const base64data: any = await getBase64(e.target.files[0]);
      setLogo(base64data);
      // localStorage.setItem(riskAppTerms.riskAppLogo, base64data);
      http.updateLogo({id: 1, setLogo, payload: {logo: base64data}});
    }
  }, []);

  const {pathname: currentPath} = useLocation();

  const dispatch = useAppDispatch();

  const routeMatch = useRouteMatch("/deals/:id");

  const routeMatchTracker = useRouteMatch("/deal-tracker/:id");

  const pathname = React.useMemo(() => currentPath, [currentPath]);

  const {authenticated} = useAppSelector(state => state.authSlice);

  const {REACT_APP_USER}: any = process.env;

  return (
    <>
      {authenticated || readLocalStorage(REACT_APP_USER) ? (
        <section
          style={{width: "12rem"}}
          className={`position-sticky  ${styles.container} py-5 px-1 d-none d-sm-none d-md-none d-lg-none d-xl-block`}
        >
          <RenderAddIcon
            {...{
              handleAddLogo,
              logo,
              setLogo,
              handleDeleteLogo,
              handleUpdateLogo,
            }}
          />

          <aside className="mt-2">
            {sideNavData.map(navData =>
              sideMenuList({navData, pathname, routeMatch, routeMatchTracker})
            )}
          </aside>

          {!isBoxConnect && (
            <button onClick={getAuthorize} className="btn btn-primary mt-5">
              Connect Box
            </button>
          )}

          <aside
            className={`position-absolute w-100 text-center container-fluid  ${styles.loginBar}`}
            style={{height: "18vh", bottom: "0px", left: "0px"}}
          >
            <aside
              className={`d-flex align-items-center justify-content-center mb-2 p-2 ${styles.borderBottom}`}
            >
              <div
                className={`${styles.userIcon} d-flex justify-content-center align-items-center ml-1`}
              >
                <UserSvg />
              </div>
              <div className={`${styles.user} d-flex align-items-center ml-1`}>
                <span className="mx-2">{getUserName()}</span>
              </div>
            </aside>
            <button
              type="button"
              onClick={() => dispatch(updateModal(true))}
              className={`${styles.button} p-2`}
            >
              <div className="d-flex align-items-center">
                <aside className="">
                  <span>Log Out</span>
                </aside>
                <aside className="mx-2">
                  <img src={LogOutIcon} alt={LogOutIcon} />
                </aside>
              </div>
            </button>
          </aside>
        </section>
      ) : null}
    </>
  );
}

export default React.memo(SideMenu);
