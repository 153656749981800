import React from "react";

import { useLocation } from "react-router-dom";

import axios from "axios";

import { saveSession } from "../utils/readLocalStorage";
import { getSession } from "./../utils/readLocalStorage";
import { clearLocalStorage } from "./../utils/clearLocalStorage";
import { useQuery } from "react-query";

const {
  REACT_APP_CLIENT_ID: clientID,
  REACT_APP_CLIENT_SECRET: clientSecret,
  REACT_APP_REDIRECT_URI: redirectURI,
  REACT_APP_AUTHENTICATION_URL: authenticationUrl,
  REACT_APP_BOX_TOKEN: tokenName,
} = process.env;

const tokenUrl = `${authenticationUrl}/token`;

const authorizeUrl = `${authenticationUrl}/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}`;

export const getAuthorize = () => {
  try {
    window.location.href = authorizeUrl;
  } catch (err) {}
};

export const getRefreshTokenSequence = (
  validateTokenAlert: () => void,
  onRefreshError: () => void
) => {
  const refereshToken = getSession(tokenName)?.refresh_token;

  if (!refereshToken) validateTokenAlert();

  return async function getRfToken(
    cb: (arg: { [key: string]: any }) => void,
    retryCount: number = 1
  ) {
    await axios
      .post(tokenUrl, {
        grant_type: "refresh_token",
        refresh_token: refereshToken,
        client_id: String(clientID),
        client_secret: String(clientSecret),
      })
      .then(cb)
      .catch((err) => {
        if (retryCount <= 3) {
          setTimeout(() => {
            getRfToken(cb, retryCount + 1);
          }, 1000);
        } else onRefreshError();
      });
  };
};

const getRefreshToken = async (
  token: string,
  setAuthState: (arg: boolean) => void
) => {
  await axios
    .post(tokenUrl, {
      grant_type: "refresh_token",
      refresh_token: token,
      client_id: String(clientID),
      client_secret: String(clientSecret),
    })
    .then((response) => {
      saveSession(tokenName, {
        ...response.data,
        dateCreated: new Date().getMilliseconds(),
      });

      setAuthState(true);
    })
    .catch((err) => {
      setAuthState(false);
    });
};

const getAccessToken = async (
  code: string,
  setAuthState: (arg: boolean) => void
) => {
  axios
    .post(tokenUrl, {
      grant_type: "authorization_code",
      code,
      client_id: clientID,
      client_secret: clientSecret,
    })
    .then((response: any) => {
      saveSession(tokenName, {
        ...response.data,
        dateCreated: new Date().getMilliseconds(),
      });

      setAuthState(true);
    })
    .catch((err) => {
      setAuthState(false);
      clearLocalStorage(tokenName);
    });
};

export const useBoxConnect = (pathname: string) => {
  const location = useLocation();

  const [authState, setAuthState] = React.useState(!!getSession(tokenName));

  const currentSession = getSession(tokenName);

  const timeToRefresh = 3600000 / 5;

  const memoizedRefresh = React.useCallback(
    () => getRefreshToken(currentSession?.refresh_token, setAuthState),
    //eslint-disable-next-line
    []
  );

  useQuery(["refreshToken"], memoizedRefresh, {
    retry: Infinity,
    refetchInterval: timeToRefresh,
    enabled: !!currentSession?.refresh_token,
    refetchIntervalInBackground: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!!currentSession?.access_token || authState) return;

    const key = location?.search;

    const code = key.slice(key.indexOf("=") + 1);

    code && getAccessToken(code, setAuthState);

    //eslint-disable-next-line
  }, []);

  return [{ isBoxConnect: !!currentSession }];
};
