import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { modalSliceData } from "../initial-data/";

const modalSlice = createSlice({
  name: "modalSlice",
  initialState: modalSliceData,
  reducers: {
    updateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.show = payload;
    },
  },
});

export const { updateModal } = modalSlice.actions;

export default modalSlice.reducer;
