import React from "react";

export default function SuspenseFallback() {
  return (
    <div
      className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10 d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="spinner-grow spinner-grow-sm text-success"></div>
      <div>Initializing...</div>
    </div>
  );
}
