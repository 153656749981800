export const feedsAnim = {
  hidden: {
    opacity: 0,
    x: "20%",
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      // type: 'spring',
      when: "beforeChildren",
    },
  },

  exit: {
    x: "50%",
    opacity: 0,
    transition: { ease: "easeInOut", when: "beforeChildren" },
  },
};

export const feedsAnimLP = {
  hidden: {
    opacity: 0,
    x: "-50%",
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      // type: 'spring',
      // stiffness: 120,
      delay: 0,
      when: "beforeChildren",
    },
  },

  exit: {
    x: "-50%",
    opacity: 0,
    transition: { ease: "easeInOut", when: "beforeChildren" },
  },
};

export const XtranslateLeft: any = {
  hidden: {
    opacity: 0,
    y: "-50%",
  },

  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 120,
      delay: 0,
      when: "beforeChildren",
    },
  },

  exit: {
    y: "50%",
    opacity: 0,
    transition: { ease: "easeInOut", when: "beforeChildren" },
  },
};

export const XtranslateRight: any = {
  hidden: {
    opacity: 0,
    x: "30%",
  },

  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 160,
      delay: 0.2,
    },
  },

  exit: {
    x: "50%",
    opacity: 0,
    transition: { ease: "easeIn" },
  },
};

export const feedsAnimMsg = {
  hidden: {
    opacity: 0,
    x: "40%",
    scale: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      type: "tween",
      delay: 0,
    },
  },

  exit: {
    x: "-40%",
    opacity: 0,
    transition: { ease: "easeInOut" },
  },
};

export const feedsAnimMsgLeft = {
  hidden: {
    opacity: 0,
    x: "-50%",
    scale: 0,
  },

  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: {
      type: "tween",
      delay: 0,
    },
  },

  exit: {
    x: "50%",
    opacity: 0,
    transition: { ease: "easeInOut" },
  },
};

export const bouncer = {
  bounce: {
    x: [-30, -20, -10, 0, 10, 20, 30],
    // y: [-1, 1, -1, 1, -1],
    transition: {
      x: {
        yoyo: Infinity,
        // duration: 0.5
      },
      y: {
        // yoyo: Infinity,
        // duration: 0.25,
        ease: "easeOut",
      },
    },
  },
};

export const authExAnim = {
  bounce: {
    x: [-30, -20, -10, 0, 10, 20, 30],
    y: [-10, -5, 0, 5, 10],
    transition: {
      x: {
        yoyo: Infinity,
        duration: 0.5,
      },
      y: {
        yoyo: Infinity,
        duration: 0.25,
        ease: "easeOut",
      },
    },
  },
};

export const userCommentsAnim = {
  hidden: {
    opacity: 0.4,
    y: "-20%",
  },

  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
    },
  },

  exit: {
    x: "50%",
    opacity: 0,
    transition: { ease: "easeInOut" },
  },
};

// export const parentAnimSinglePost = {
//     visible: { opacity: 1 },
//     hidden: { opacity: 0 },
//     transition: { when: 'beforeChildren'}
// }

export const childAnimSinglePost = {
  hidden: {
    y: "-50vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 120,
      when: "beforeChildren",
    },
  },
};

export const savedPostAnim = {
  hidden: {
    y: "50vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: { delay: 0.5, type: "tween", when: "beforeChildren" },
  },
};

export const backdrop: any = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
  transition: { when: "beforeChildren" },
};

export const modal: any = {
  hidden: {
    y: "100vh",
    opacity: 0,
  },
  visible: {
    y: "200px",
    opacity: 1,
    transition: { type: "spring", stiffness: 120, when: "beforeChildren" },
  },
};

export const modalFile: any = {
  hidden: {
    y: "10vh",
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 120, when: "beforeChildren" },
  },
};
