import { clearLocalStorage } from "./clearLocalStorage";
import { handleAuthentication } from "../store/slices/authSlice";

const { REACT_APP_USER }: any = process.env;

export const handleLogout = (history: any, dispatch: any) => {
  dispatch(handleAuthentication(false));
  clearLocalStorage(REACT_APP_USER);
  clearLocalStorage('report');
  localStorage.removeItem("RISKAPP_LOGO");
  history.push("/login");
  window.location.reload();
};
