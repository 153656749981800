import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";

const ErrorBoundaryProvider: any = ({ children }) => {
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {};

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryProvider;
