import { useMutation, useQuery, useQueryClient } from "react-query";

import { useHistory } from "react-router";

import { useAppDispatch } from "../store/storeConfig";

import { http } from "./http";

import { ILoginValues } from "./../models/loginValues";

import { toast } from "react-toastify";

export const toastError = (err: string, time: number) => {
  toast.error(err, {
    position: "bottom-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const toastInfo = (msg: string, time: number) => {
  toast.info(msg, {
    position: "bottom-center",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const useLogin = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  return useMutation((data: ILoginValues) =>
    http.login("/login", data, history, dispatch)
  );
};

export const useGetReports = () => {
  return useQuery<{
    id: string;
    name: string;
    link: string;
    description: string;
    date: string;
  }[]
  >(['reports'], () => http.get('/report/list'))
}

export const useGetReviewOrder = (
  path: string,
  queryPageIndex: number,
  queryPageSize: number,
  searchKey: string,
  sortKey: string,
  filters: string
) => {
  return useQuery(
    [
      "order",
      path,
      queryPageIndex,
      queryPageSize,
      searchKey && searchKey,
      sortKey && sortKey,
      filters,
    ],
    () =>
      http.getTable({
        path,
        page: queryPageIndex,
        pageSize: queryPageSize,
        searchKey,
        sortKey,
        filters,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

export const useGet = (path: string) => {
  return useQuery([path], () => http.get(path), {
    keepPreviousData: true,
    staleTime: Infinity,
  });
};

export const useGetSingleOrder = (path: string, id: number) => {
  return useQuery(["single-order", path, id], () => http.getSingle(path, id), {
    keepPreviousData: true,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: typeof id === "number" && !!id,
  });
};

export const usePostOrder = (path: string, status?: string) => {
  const queryClient = useQueryClient();

  return useMutation((payload: any) => http.post(path, payload), {
    onSuccess: (v) => {
      if (String(v)?.toLowerCase()?.includes("error") || !v) {
        toastError("Couldn't Save, Try Again", 2000);
      } else if (v === "permission denied for function crud_review_order") {
        toastError("Couldn't Save, Try Again", 2000);
      } else {
        if (status === "new") {
          toastInfo("Deal successfully added!", 3000);
        } else {
          toastInfo("Successfully Updated!", 3000);
        }

        queryClient.invalidateQueries(["order", "/orders"]);
        queryClient.invalidateQueries([
          "/order/tracker/" + Number(v?.order_id),
        ]);
        queryClient.invalidateQueries([
          "single-order",
          "/order",
          Number(v?.order_id),
        ]);
      }
    },
    onError: () => {
      toastError("Try Again, an error occured while saving...", 2000);
    },
    onSettled: (v) => {
      queryClient.invalidateQueries([
        "single-order",
        "/order",
        Number(v?.order_id),
      ]);
      queryClient.invalidateQueries(["order", "/orders"]);
    },
  });
};

export const useGetLogo = (path: string, setLogo: any) => {
  return useQuery(["get/logo", path], () => http.getLogo(path, setLogo), {
    keepPreviousData: true,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !localStorage.getItem("RISKAPP_LOGO"),
  });
};

export const usePost = ({
  path,
  message,
  position,
  key,
  key2,
  cb,
  showErr,
}: {
  path: string;
  message?: string;
  position?: any;
  key?: string | Array<string>;
  key2?: Array<any>;
  showErr?: boolean;
  cb?: (arg: boolean) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation((data) => http.post(path, data), {
    onSuccess: () => {
      message &&
        toast.success(message, {
          position: position ? position : "bottom-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

      key && queryClient.invalidateQueries(key);
      key2 && queryClient.invalidateQueries(key2);

      cb && cb(false);
    },

    onError: () => {
      //cb && cb(false);

      showErr && toastError("Try Again, an error occured...", 3000);
    },
  });
};



