interface ITableSliceProps {
  queryPageIndex: number;
  queryPageSize: number;
  totalCount: null | number;
}

export const tableSliceData: ITableSliceProps = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
};

interface IAuthSliceDataProps {
  authenticated: boolean;
}

export const authSliceData: IAuthSliceDataProps = {
  authenticated: false,
};

interface IModalProps {
  show: boolean;
}

export const modalSliceData: IModalProps = {
  show: false,
};

export interface IFileHandlerProps {
  data: string;
  filename: string;
}

export const fileHandlerData: IFileHandlerProps = {
  data: "",
  filename: "",
};

export interface IshowFileProps {
  show: boolean;
}

export const showFileData: IshowFileProps = {
  show: false,
};


