import React from "react";

export default function ConsultantIcon({ color }: { color: string }) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4545 11.6364V10.4545C10.4545 9.82767 10.2055 9.22647 9.76225 8.78321C9.31898 8.33994 8.71778 8.09091 8.09091 8.09091H3.36364C2.73676 8.09091 2.13556 8.33994 1.69229 8.78321C1.24903 9.22647 1 9.82767 1 10.4545V11.6364"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.72741 5.72727C7.03281 5.72727 8.09104 4.66904 8.09104 3.36364C8.09104 2.05824 7.03281 1 5.72741 1C4.42201 1 3.36377 2.05824 3.36377 3.36364C3.36377 4.66904 4.42201 5.72727 5.72741 5.72727Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.6364V10.4545C13.9996 9.93084 13.8253 9.42209 13.5045 9.00818C13.1836 8.59428 12.7344 8.29865 12.2273 8.16772"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86377 1.07681C10.3722 1.20699 10.8228 1.50268 11.1446 1.91727C11.4665 2.33186 11.6411 2.84176 11.6411 3.36659C11.6411 3.89141 11.4665 4.40131 11.1446 4.8159C10.8228 5.23049 10.3722 5.52618 9.86377 5.65636"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
